.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 40s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg) scale(0.5,0.5);
  }
  to {
    transform: rotate(360deg) scale(1,1);
  }
}

.error {
  font-size: 12px;
  color: #f44336;
  margin-top: 0;
  margin-left: 8px;
}

.error::before {
  display: inline;
  font-size: 16px;
  font-weight: bold;
  content: "⚠ ";
}

.snackbar-error {
  background-color: #d9534f !important;
}

.snackbar-warning {
  background-color: #ff9800 !important;
}

.snackbar-success {
  background-color: #2b9c3e !important;
}

.snackbar-info {
  background-color: #4c7fb9 !important;
}

.snackbar-text {
  background-color: #adadad !important;
}

.headlineBox {
  margin: 15px 0 8px 0;
  padding: 0 10px 0 0;
  background-color: #dedede;
  font-size: 0.9em;
  align-items: stretch;
}

.subHeadlineBox {
  margin-bottom: 9px;
}

.headlineIconBox {
  padding: 9px 10px 9px 10px;
  margin-right: 10px;
  background-color: #cacaca;
}

.headlineIconText {
  margin-left: 8px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #4f4f4f;
}

.tooltipAction {
  color: #ade7a9;
}

.tooltipAction::before {
  font-weight: bold;
  content: "↪ ";
}
